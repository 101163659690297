<template>
	<div>
		<Navbar></Navbar>
		<div class="services">
			<div class="text-center mb-50">
				<img alt="services" class="case-studies" src="../assets/services.svg"/>
			</div>

			<div class="services-container">
				<img alt="ideation" class="services-icon" src="../assets/icons/ideation.svg"/>
				<div class="services-text">
					<div class="services-title">
						Strategy & Discovery
					</div>
					<div class="services-description">
						This phase involves understanding the product's purpose, gathering business insights, analytics,
						and data about the target audience and market. Through research on competitors and user
						motivations,
						we shape a strong product vision, laying the foundation for design.
					</div>
					<div class="box-container">
						<div>
							<div class="services-shadowed-boxes">
								Product research
							</div>
						</div>
						<div>
							<div class="services-shadowed-boxes">
								Workshops
							</div>
						</div>
						<div>
							<div class="services-shadowed-boxes">
								UX/UI audit
							</div>
						</div>
						<div>
							<div class="services-shadowed-boxes">
								Persona
							</div>
						</div>
						<div>
							<div class="services-shadowed-boxes">
								User interview
							</div>
						</div>
						<div>
							<div class="services-shadowed-boxes">
								Brainstorming
							</div>
						</div>
					</div>
				</div>
			</div>

			<div class="services-container">
				<img alt="ux/ui" class="services-icon" src="../assets/icons/design.svg"/>
				<div class="services-text">
					<div class="services-title">
						UX & UI Design
					</div>
					<div class="services-description">
						This service involve designing digital products with a focus on creating a seamless and visually
						appealing user experience. UX focuses on understanding user needs, while UI ensures an intuitive and
						aesthetically pleasing interface. Together, they aim to deliver a compelling and user-friendly digital experience.
					</div>
					<div class="box-container">
						<div>
							<div class="services-shadowed-boxes">
								Wireframe
							</div>
						</div>
						<div>
							<div class="services-shadowed-boxes">
								Prototype
							</div>
						</div>
						<div>
							<div class="services-shadowed-boxes">
								UI Design
							</div>
						</div>
						<div>
							<div class="services-shadowed-boxes">
								UI Design
							</div>
						</div>
					</div>
				</div>
			</div>

			<div class="services-container">
				<img alt="graphic-design" class="services-icon" src="../assets/graphic-design.svg"/>
				<div class="services-text">
					<div class="services-title">
						Graphic Design
					</div>
					<div class="services-description">
						Creating visual content to communicate messages effectively. Various elements like images, typography,
						and colors to craft visually appealing materials such as logos, brochures, and social media assets.
						The goal is to convey information, enhance brand identity, and engage the audience through thoughtful and visually compelling design.
					</div>
					<div class="box-container">
						<div>
							<div class="services-shadowed-boxes">
								Brand identity
							</div>
						</div>
						<div>
							<div class="services-shadowed-boxes">
								Logo design
							</div>
						</div>
						<div>
							<div class="services-shadowed-boxes">
								Social media content
							</div>
						</div>
						<div>
							<div class="services-shadowed-boxes">
								Online advertising materials
							</div>
						</div>
						<div>
							<div class="services-shadowed-boxes">
								Offline advertising materials
							</div>
						</div>
					</div>
				</div>
			</div>

			<div class="text-center mt-30">
				<router-link class="button button--coral" to="/get-in-touch">Ask for an offer</router-link>
			</div>
		</div>

		<Contact></Contact>
	</div>

</template>

<script lang="ts">
import Contact from "@/components/Contact.vue";
import Navbar from "@/components/Navbar.vue";
import Discovery from "@/components/ux-ui/Discovery.vue";
import Research from "@/components/ux-ui/Research.vue";
import Ideation from "@/components/ux-ui/Ideation.vue";
import Design from "@/components/ux-ui/Design.vue";
import Test from "@/components/ux-ui/Test.vue";
import {defineComponent, ref} from "vue";

export default defineComponent({
	components: {
		Test,
		Design,
		Ideation,
		Research,
		Navbar,
		Contact,
		Discovery
	},
	data() {
		return {
			discoveryIsActive: true,
			researchIsActive: false,
			ideationIsActive: false,
			designIsActive: false,
			testIsActive: false,
		};
	},
});
</script>

<style scoped>

</style>
