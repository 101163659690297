<template>
	<div class="footer">
		<div>
			<div class="is-grid">
				<h2>Contact info</h2>
				<div class="contact-info">scrollrolldesign@gmail.com</div>
				<div class="contact-info">+40 731 323 660</div>
			</div>

			<div class="contact-container">
				<a href="https://www.linkedin.com/in/noemi-pal-uxui-graphic-designer/">
					<img alt="linkedin" class="contact-icon" src="../assets/contact/linkedin.svg"/>
				</a>
				<a href="https://www.instagram.com/scrollrolldesign/">
					<img alt="instagram" class="contact-icon" src="../assets/contact/instagram.svg"/>
				</a>
				<a href="https://www.facebook.com/palnociii/">
					<img alt="facebook" class="contact-icon" src="../assets/contact/facebook.svg"/>
				</a>
				<a href="https://www.behance.net/plnomi">
					<img alt="be" class="contact-icon" src="../assets/contact/be.svg"/>
				</a>
			</div>
		</div>

		<div>
			<img alt="footer-logo" class="footer-logo" src="../assets/contact/footer-logo.svg"/>
		</div>

	</div>
</template>

<script lang="ts">
import {defineComponent} from "vue";

export default defineComponent({});
</script>

<style scoped>

</style>
