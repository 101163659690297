<template>
	<div class="is-relative">
		<Navbar></Navbar>
		<div class="contractual-first-panel-container">
			<img alt="arrow-left" class="arrow-left" src="../../assets/chevron-right-white.svg" @click="goBack"/>
			<img alt="contractual" class="laptop" src="../../assets/case-study/contractual-background.png"/>
			<div class="contractual-title-container">
				<div class="case-study-title">Contractual</div>
				<div class="case-study-text mt-30">
					A simple, efficient and up-to-date solution for contract management.
				</div>
			</div>
		</div>

		<div class="case-study-second-panel-container">
			<div class="contractual-second-panel-box">
				<h2 class="mt-100 mb-30">ContrActual</h2>
				<p class="width-68p">
					ContrActual is a digital platform for contract lifecycle management in a single fully-automated flow.
					ContrActual is your company's centralized solution for all types of contracts.
					It integrates all aspects specific to contract management: drafting, editing, negotiation,
					approval, signing, monitoring, renewal, completion.

				</p>
			</div>

			<img alt="hotbed-screen" class="contractual-screen" src="../../assets/case-study/contractual-screen.png"/>
		</div>

		<div class="third-panel-container">
			<div class="overview-title">Project Overview</div>
			<div class="third-panel-grid">
				<div>
					<div class="overview-subtitle">CHALLENGE</div>
					<div class="overview-text mb-30">
						Revamping the user experience to simplify contract management and creation.
					</div>

					<div class="overview-subtitle">SOLUTION</div>
					<ol>
						<li class="overview-text">Relocate buttons</li>
						<li class="overview-text">Making contract templates that make the process of creating new contracts faster</li>
						<li class="overview-text">Shortcuts</li>
						<li class="overview-text">Clear sectioning</li>
					</ol>

				</div>

				<div>
					<div class="overview-subtitle">ROLE</div>
					<div class="overview-text mb-30">UX/UI Designer</div>
					<div class="overview-subtitle">TIME</div>
					<div class="overview-text mb-30">5 weeks</div>
					<div class="overview-subtitle">TASK</div>
					<div class="overview-text mb-30">Redesign</div>
					<div class="overview-subtitle">TOOL</div>
					<div class="overview-text mb-30">Figma</div>
				</div>
			</div>
		</div>

		<div class="fourth-panel-container">
			<h2 class="mb-100">Design process</h2>
			<div class="ux-ui-design-type-container">
				<div class="ux-ui-design-item">
					<img alt="discovery" class="category-icon" src="../../assets/icons/discovery.svg"/>
					<div class="design-type-title">Discovery</div>
					<div class="design-type-description">Defining the problem</div>
				</div>

				<div class="ux-ui-design-item">
					<img alt="research" class="category-icon" src="../../assets/icons/ideation.svg"/>
					<div class="design-type-title">Ideation</div>
					<div class="design-type-description">Brainstorming<br>UX audit</div>
				</div>

				<div class="ux-ui-design-item">
					<img alt="ideation" class="category-icon" src="../../assets/icons/design.svg"/>
					<div class="design-type-title">Design</div>
					<div class="design-type-description">Redesign</div>
				</div>

				<div class="ux-ui-design-item">
					<img alt="design" class="category-icon" src="../../assets/icons/test.svg"/>
					<div class="design-type-title">Design</div>
					<div class="design-type-description">Usability testing</div>
				</div>
				<div class="dashed-line"></div>
			</div>

			<h2 class="mb-30">Discovery</h2>
			<div class="design-process-text mb-50">
				In the discovery stage, I conducted online meetings with the team to explore improved solutions for structuring the app.
				We systematically examined the app, conducted tests, and discussed areas for enhancement.
				Together, we identified solutions that enhanced the UX process, making it more seamless and functional.
				<br><br>
				Following this, I addressed questions regarding the menu, features,
				and the process of creating and managing contracts during a client meeting.
			</div>

			<h2 class="mb-30">Ideation</h2>
			<div class="overview-subtitle">UX audit</div><br>
			<div class="design-process-text mb-50">
				Based on the previous brainstorming and meetings where we defined the problems
				I formulated suggestions about what we should redesign.
				<br><br>
				<a class="underline"
				   href="https://www.figma.com/file/sbtjt43Utvg0buADlmKCzx/Contractual-UX-audit?type=whiteboard&node-id=0-1">
					ContrActual UX audit
				</a>
			</div>

			<h2 class="mb-30">Design</h2>
			<div class="design-process-text">
				Once the client approved the UX audit findings, the next step involved reconstructing the existing elements.
				This reconstruction process was guided by the insights and ideas gathered during the earlier discovery and ideation
				stages. The aim was to implement improvements and refinements based on the identified issues and innovative concepts,
				ensuring that the final product aligns more closely with the desired user experience.
			</div>
		</div>

		<div class="documents-container">
			<img alt="mockup" class="mockup" src="../../assets/case-study/contractual-mockup1.svg"/>
			<img alt="mockup" class="mockup" src="../../assets/case-study/contractual-mockup2.svg"/>
		</div>

		<div class="fourth-panel-container">
			<h2 class="mb-30">Test</h2>
			<div class="design-process-text mb-50">
				Fot the usability testing I prepared some questions and tasks that users were asked to complete step by step.
			</div>

			<div class="overview-subtitle mb-50">Tasks</div>

			<ol class="graphic-design-type-content mb-50">
				<li>Sign in</li>
				<li>Enter your company details in the application</li>
				<li>Create a contract, with a first chapter that includes the data of the contracting parties</li>
				<li>In a subsequent chapter, briefly specify the method of communication by inserting the email address from the first partner. You can use the following text for the chapter:</li>
				<li>The official mode of communication is by email to the address ... in the case of the PROVIDER, respectively to the address ... in the case of the BENEFICIARY.</li>
				<li>In the "communication mode" chapter, add another article in which you specify the name of the representative</li>
				<li>Open the contract in preview</li>
				<li>Save the contract and then send to the customer for signature</li>
				<li>Create three more identical contracts with other partners.</li>
				<li>From the contracts created, choose one in which you add a third partner. Delete one of them and then change the order of the partners</li>
				<li>For the first partner, delete the CIF</li>
				<li>Add a task to the last contract</li>
				<li>Add another user to the account</li>
				<li>Create a subaccount for another company, associated with the current user</li>
			</ol>

			<ul class="graphic-design-type-content">
				<li>How do you feel, where have you encountered obstacles?</li>
				<li>Which tasks did you have difficulty completing?</li>
				<li>Which features didn't you find easy?</li>
				<li>Which features did you find easy?</li>
			</ul>
		</div>

		<div class="footer-container mb-30">
			<router-link class="is-flex pointer" to="/case-study/kompozit">
				<img alt="chevron-left" class="footer-arrow-left" src="../../assets/arrow-down.svg"/>
			</router-link>
			<div class="thanks">Thanks for watching!</div>
			<router-link class="is-flex pointer" to="/case-study/pertu">
				<img alt="chevron-right" class="footer-arrow-right" src="../../assets/arrow-down.svg"/>
			</router-link>
		</div>

		<img alt="chevron-up" class="footer-arrow-up" src="../../assets/arrow-down.svg" v-on:click="goToTop"/>
	</div>
</template>

<script lang="ts">
import {defineComponent} from "vue";
import Navbar from "@/components/Navbar.vue";

export default defineComponent({
	components: {
		Navbar
	},
	methods: {
		goBack() {
			this.$router.go(-1);
		},
		goToTop() {
			window.scrollTo(0, 0);
		}
	}
});

</script>

<style scoped>

</style>

