<template>
	<div class="is-relative">
		<Navbar></Navbar>
		<div class="flatco-container">
			<img alt="arrow-left" class="arrow-left" src="../../assets/chevron-right-white.svg" @click="goBack"/>
			<div class="case-study-title-container">
				<div class="case-study-title">flatco</div>
				<div class="case-study-text mt-30">
					Leading owner and operator of <br> residential real estate
				</div>
			</div>
		</div>

		<div class="case-study-second-panel-container">
			<div class="contractual-second-panel-box">
				<h2 class="mb-30">Flatco</h2>
				<p>
					Flatco Real Estate kft is Budapest’s leading owner and operator of residential real estate.
					We rent out top quality apartments for long term rent, as well as shops, offices, parking spots,
					and storage units all over Budapest. Our apartments come fully furnished and perfectly suited to
					become the place you call ‘Home’.
				</p>
			</div>

			<img alt="flatco-screen" class="flatco-screen" src="../../assets/case-study/flatco-screen.svg"/>
		</div>

		<div class="third-panel-container">
			<div class="overview-title">Project Overview</div>
			<div class="third-panel-grid">
				<div>
					<div class="overview-subtitle">CHALLENGE</div>
					<div class="overview-text mb-30">
						<ul>
							<li class="overview-text">
								Redesigning the UI and restructuring some elements
							</li>
							<li class="overview-text">
								Unifying the style between the main page and the subpages
							</li>
						</ul>
					</div>

					<div class="overview-subtitle">SOLUTION</div>
					<ol>
						<li class="overview-text">Restructuring navigation</li>
						<li class="overview-text">Creating Tenant dashboard</li>
					</ol>

				</div>

				<div>
					<div class="overview-subtitle">ROLE</div>
					<div class="overview-text mb-30">UX/UI Designer</div>
					<div class="overview-subtitle">TIME</div>
					<div class="overview-text mb-30">5 weeks</div>
					<div class="overview-subtitle">TASK</div>
					<div class="overview-text mb-30">UI redesign</div>
					<div class="overview-subtitle">TOOL</div>
					<div class="overview-text mb-30">Figma, Adobe Illustrator</div>
				</div>
			</div>
		</div>

		<div class="design-container">
			<h2 class="mt-30 mb-100">Design process</h2>

			<div class="design-process-container">
				<div class="ux-ui-design-item">
					<img alt="discovery" class="category-icon" src="../../assets/icons/discovery.svg"/>
					<div class="design-type-title">
						Discovery
					</div>
					<div class="design-type-description">Meetings with stakeholders <br>
						and project managers
					</div>
				</div>

				<div class="ux-ui-design-item">
					<img alt="research" class="category-icon" src="../../assets/icons/research.svg"/>
					<div class="design-type-title">
						Research
					</div>
					<div class="design-type-description">Product research
					</div>
				</div>


				<div class="ux-ui-design-item">
					<img alt="design" class="category-icon" src="../../assets/icons/design.svg"/>
					<div class="design-type-title">Design
					</div>
					<div class="design-type-description">
						Wireframing <br>
						UI design
					</div>
				</div>
				<div class="dashed-line"></div>
			</div>
		</div>

		<div class="wireframing-container">
			<h2 class="mb-50">Wireframing</h2>

			<div class="is-flex space-between">
				<img alt="wireframe-image" class="wireframe-image" src="../../assets/case-study/wireframe1.svg"/>
				<div class="wireframe-column space-between">
					<img alt="wireframe-image" class="wireframe-image" src="../../assets/case-study/wireframe2.svg"/>
					<img alt="wireframe-image" class="wireframe-image" src="../../assets/case-study/wireframe3.svg"/>
					<img alt="wireframe-image" class="wireframe-image" src="../../assets/case-study/wireframe4.svg"/>

				</div>
				<div class="wireframe-column space-between">
					<img alt="wireframe-image" class="wireframe-image" src="../../assets/case-study/wireframe5.svg"/>
					<img alt="wireframe-image" class="wireframe-image" src="../../assets/case-study/wireframe6.svg"/>
				</div>
			</div>
		</div>

		<div class="design-container">
			<h2 class="mt-30 mb-30 is-gray">User Interface</h2>

			<div class="landing-container">
				<div class="landing-page-title">
					Landing page
				</div>

				<div class="landing-image-container">
					<img alt="flatco-laptop" class="flatco-laptop" src="../../assets/case-study/flatco-laptop.svg"/>
					<img alt="landing-page" class="flatco-image" src="../../assets/case-study/landing-page.svg"/>
				</div>
			</div>
		</div>

		<div class="gray-background-container"></div>

		<div class="services">
			<div class="is-flex space-between">
				<div class="wireframe-column space-between">
					<img alt="landing-page-image" class="landing-page-images"
						 src="../../assets/case-study/flatco-landing1.svg"/>
					<img alt="landing-page-image" class="landing-page-images"
						 src="../../assets/case-study/flatco-landing2.svg"/>
					<img alt="landing-page-image" class="landing-page-images"
						 src="../../assets/case-study/flatco-landing3.svg"/>
					<img alt="landing-page-image" class="landing-page-images"
						 src="../../assets/case-study/flatco-landing4.svg"/>
				</div>
				<div class="wireframe-column space-between">
					<img alt="landing-page-image" class="landing-page-images"
						 src="../../assets/case-study/flatco-landing5.svg"/>
					<img alt="landing-page-image" class="landing-page-images"
						 src="../../assets/case-study/flatco-landing6.svg"/>
					<img alt="landing-page-image" class="landing-page-images"
						 src="../../assets/case-study/flatco-landing7.svg"/>
				</div>
			</div>
		</div>

		<div class="overflow-container">
			<div>
				<div class="landing-page-title">
					Bárd <br> page
				</div>
				<img alt="bard" class="overflow-images" src="../../assets/case-study/bard.svg"/>

			</div>

			<div>
				<div class="landing-page-title">
					Teve <br> subpage
				</div>
				<img alt="bard" class="overflow-images" src="../../assets/case-study/teve.svg"/>
			</div>
		</div>

		<div class="gray-background-sub-container"></div>

		<div class="tenant-container">
			<div class="landing-page-title">
				Tenant <br> dashboard
			</div>

			<div class="landing-image-container">
				<img alt="flatco-laptop" class="dashboard-image" src="../../assets/case-study/dashboard.svg"/>
			</div>
		</div>

		<div class="footer-container mb-30">
			<router-link class="is-flex pointer" to="/case-study/contractual">
				<img alt="chevron-left" class="footer-arrow-left" src="../../assets/arrow-down.svg"/>
			</router-link>
			<div class="thanks">Thanks for watching!</div>
			<router-link class="is-flex pointer" to="/case-study/pen">
				<img alt="chevron-right" class="footer-arrow-right" src="../../assets/arrow-down.svg"/>
			</router-link>
		</div>

		<img alt="chevron-up" class="footer-arrow-up" src="../../assets/arrow-down.svg" v-on:click="goToTop"/>

	</div>
</template>

<script lang="ts">

import {defineComponent} from "vue";
import Navbar from "@/components/Navbar.vue";

export default defineComponent({
	components: {
		Navbar
	},
	methods: {
		goBack() {
			this.$router.go(-1);
		},
		goToTop() {
			window.scrollTo(0, 0);
		}
	}
});

</script>

<style scoped>

</style>
