import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = { class: "pl-90 bar-chart-grid" }
const _hoisted_2 = {
  key: 0,
  class: "semi-bold"
}
const _hoisted_3 = {
  key: 1,
  class: "semi-bold"
}
const _hoisted_4 = { class: "bar-chart" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", null, [
        (_ctx.isTool)
          ? (_openBlock(), _createElementBlock("p", _hoisted_2, _toDisplayString(_ctx.toolName), 1))
          : (_openBlock(), _createElementBlock("p", _hoisted_3, _toDisplayString(_ctx.languages), 1))
      ]),
      _createElementVNode("div", null, [
        _createElementVNode("div", _hoisted_4, [
          (_ctx.isTool)
            ? (_openBlock(), _createElementBlock("div", {
                key: 0,
                class: "content",
                style: _normalizeStyle({ width: `${_ctx.toolLevel}%` })
              }, null, 4))
            : (_openBlock(), _createElementBlock("div", {
                key: 1,
                class: "content",
                style: _normalizeStyle({ width: `${_ctx.languageLevel}%` })
              }, null, 4))
        ])
      ])
    ])
  ]))
}