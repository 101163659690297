import { createApp } from 'vue'
import App from './App.vue'
import '../src/css/app.css';
import router from "@/router";
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';

const link = document.createElement('link');
link.href = 'https://fonts.googleapis.com/css?family=Ibarra+Real+Nova&display=swap';
link.href = 'https://fonts.googleapis.com/css2?family=Poppins:wght@400;600&display=swap';
link.href = 'https://fonts.googleapis.com/css2?family=Poppins:wght@400;600&display=swap';
link.href = 'https://fonts.googleapis.com/css?family=Montserrat';

link.rel = 'stylesheet';
document.head.appendChild(link);

const firebaseConfig = {
    apiKey: "AIzaSyANAzhwRx5M83sjxNZVQc-wiC0NTZIdPZU",
    authDomain: "scrollroll.design",
    projectId: "scroll-roll-design",
    storageBucket: "scroll-roll-design.appspot.com",
    messagingSenderId: "717853836409",
    appId: "1:717853836409:web:0371bd0ad7f6c56a3580a8",
    measurementId: "G-L9NRYK71P0"
};

const firebaseApp = firebase.initializeApp(firebaseConfig);
const db = firebaseApp.firestore();
const auth = firebase.auth();
export { auth, db };

console.log(firebaseApp);

createApp(App).use(router).mount('#app')
