<template>
	<div>
		<Navbar></Navbar>
		<div class="first-panel">
			<div class="main-container">
				<div class="two-column-grid">
					<div class="grid-item">
						<div class="large-title">
							UX/UI & Graphic
							<mark class="coral">Design</mark>
						</div>

						<div class="is-flex gap">
							<div class="first-panel-text">
								Creating seamless experiences where <br> design meets your and user's goals.
							</div>
						</div>
						<router-link class="button button--white--coral mt-30" to="/get-in-touch">Ask for an offer</router-link>
					</div>

					<div>
						<p class="design-instruction">You are on the right track If you need a design for:</p>
						<div class="three-column-grid">
							<router-link class="dashboard-item" to="/services"
										 @mouseover="toggleHover('web',true)" @mouseout="toggleHover('web', false)">
								<img alt="web" class="dashboard-item-icon" :src="isWebHovered ? require('../assets/dashboard/web-coral.svg') : require('../assets/dashboard/web.svg')"/>
								<span class="mt-10">Web</span>
							</router-link>
							<router-link class="dashboard-item" to="/services"
										 @mouseover="toggleHover('phone',true)" @mouseout="toggleHover('phone', false)">
								<img alt="app" class="dashboard-item-icon" :src="isPhoneHovered ? require('../assets/dashboard/phone-coral.svg') : require('../assets/dashboard/phone.svg')"/>
								<span class="mt-10">Application</span>
							</router-link>
							<router-link class="dashboard-item" to="/services"
										 @mouseover="toggleHover('software',true)" @mouseout="toggleHover('software', false)">
								<img alt="software" class="dashboard-item-icon" :src="isSoftwareHovered ? require('../assets/dashboard/software-coral.svg') : require('../assets/dashboard/software.svg')"/>
								<span class="mt-10">Software</span>
							</router-link>
							<router-link class="dashboard-item" to="/services"
										 @mouseover="toggleHover('marketing',true)" @mouseout="toggleHover('marketing', false)">
								<img alt="marketing" class="dashboard-item-icon" :src="isMarketingHovered ? require('../assets/dashboard/marketing-coral.svg') : require('../assets/dashboard/marketing.svg')"/>
								<span class="mt-10">Marketing</span>
							</router-link>
							<router-link class="dashboard-item" to="/services"
										 @mouseover="toggleHover('brand',true)" @mouseout="toggleHover('brand', false)">
							<img alt="brand" class="dashboard-item-icon" :src="isBrandHovered ? require('../assets/dashboard/brand-coral.svg') : require('../assets/dashboard/brand.svg')"/>
								<span class="mt-10">Brand</span>
							</router-link>
							<router-link class="dashboard-item" to="/services"
										 @mouseover="toggleHover('redesign',true)" @mouseout="toggleHover('redesign', false)">
								<img alt="redesign" class="dashboard-item-icon" :src="isRedesignHovered ? require('../assets/dashboard/redesign-coral.svg') : require('../assets/dashboard/redesign.svg')"/>
								<span class="mt-10">Redesign</span>
							</router-link>
						</div>
					</div>
				</div>

			</div>
			<div class="text-center hide-on-mobile-tablet">
				<img alt="transparent-icon" class="transparent-icon" src="../assets/transparent-logo.svg"/>
			</div>
		</div>

		<div class="work-group-container">
			<div class="work-group-item" v-on:click="setActive('ux-ui')">
				<img alt="" class="work-icon center" src="../assets/ux-ui-design.svg"
					 v-bind:class="{ 'work-icon-active' : isActive === 'ux-ui'}"/>
				<span class="work-group"
					  v-bind:class="{ 'work-group-active' : isActive === 'ux-ui'}">UX/UI Design</span>
			</div>
			<div class="work-group-item" v-on:click="setActive('graphic')">
				<img alt="" class="work-icon center" src="../assets/graphic-design.svg"
					 v-bind:class="{ 'work-icon-active' : isActive === 'graphic'}"/>
				<span class="work-group"
					  v-bind:class="{ 'work-group-active': isActive === 'graphic'}">Graphic Design</span>
			</div>
		</div>

		<div v-show="isActive === 'ux-ui'">
			<div class="design-container hide-on-desktop">
				<div class="text-center mb-50">
					<img alt="case-studies" class="case-studies" :src="require('@/assets/case-studies.svg')" />
				</div>
				<div class="carousel-container">
					<div class="carousel" :style="{ transform: `translateX(-${currentWorkIndex * 100}%)` }">
						<div class="carousel-item" v-for="(item, index) in workItems" :key="index">
							<div class="work-container">
								<img :alt="item.alt" class="work-container-image" :src="require(`@/assets/work/${item.src}`)" />
								<div class="work-container-text">
									<div class="work-container-title">{{ item.title }}</div>
									<div class="work-container-description">{{ item.description }}</div>
									<div>
										<router-link class="button button--white" :to="item.link">Read case study</router-link>
									</div>
								</div>
							</div>
						</div>
					</div>
					<img alt="arrow-left" class="carousel-control left" src="../assets/arrow-down.svg" @click="prevWorkSlide"/>
					<img alt="arrow-right" class="carousel-control right" src="../assets/arrow-down.svg" @click="nextWorkSlide"/>
				</div>
			</div>

			<div class="design-container hide-on-mobile-tablet">
				<div class="text-center mb-50">
					<img alt="case-studies" class="case-studies" :src="require('@/assets/case-studies.svg')" />
				</div>
				<div>
					<div class="work-container" v-for="(item, index) in workItems" :key="index">
						<img :alt="item.alt" class="work-container-image" :src="require(`@/assets/work/${item.src}`)" />
						<div class="work-container-text">
							<div class="work-container-title">{{ item.title }}</div>
							<div class="work-container-description">{{ item.description }}</div>
							<div>
								<router-link :class="['button', 'button--white']" :to="item.link">Read case study</router-link>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div class="container hide-on-mobile-tablet">
				<h2>Can’t get enough?</h2>
				<span class="blog-post">Check out our blog posts co-written with Further Digital Solutions.</span>

				<div class="post-container">
					<div class="post-box" v-for="(post, index) in posts" :key="index">
						<div class="post-title">{{ post.title }}</div>
						<div class="post-description">{{ post.description }}</div>
						<div class="post-bottom-container">
							<div class="post-date">{{ post.date }}</div>
							<a class="small-button button--coral" :href="post.link" target="_blank">Read more</a>
						</div>
					</div>
				</div>
			</div>

			<div class="carousel-container hide-on-desktop">
				<div class="carousel" :style="{ transform: `translateX(-${currentPostIndex * 100}%)` }">
					<div class="carousel-item" v-for="(post, index) in posts" :key="index">
						<div class="post-box">
							<div class="post-title">{{ post.title }}</div>
							<div class="post-description">{{ post.description }}</div>
							<div class="post-bottom-container">
								<div class="post-date">{{ post.date }}</div>
								<a class="small-button button--coral" :href="post.link" target="_blank">Read more</a>
							</div>
						</div>
					</div>
				</div>

				<img alt="arrow-left" class="carousel-control left" src="../assets/arrow-down.svg" @click="prevBlogSlide"/>
				<img alt="arrow-right" class="carousel-control right" src="../assets/arrow-down.svg" @click="nextBlogSlide"/>
			</div>

			<div class="container">
				<div class="ux-ui-title mb-50">Why work with a <mark>UX/UI designer</mark>?</div>

				<div class="ux-ui-container">
					<div class="ux-ui-item" v-for="(item, index) in uxUiItems" :key="index">
						<div class="ux-ui-item-title-container">
							<img alt="tick" class="tick" src="../assets/tick.svg" />
							<div class="ux-ui-item-title">{{ item.title }}</div>
						</div>
						<div class="ux-ui-item-text">{{ item.text }}</div>
					</div>
				</div>
			</div>
		</div>

		<div class="design-container" v-show="isActive === 'graphic'">
			<div class="text-center mb-50">
				<img alt="logofolio" class="logofolio" src="../assets/logofolio..svg"/>
			</div>

			<div class="logofolio-container">
				<router-link class="logofolio-grid-item" id="pertu" to="/case-study/pertu"><img src="../assets/logofolio/pertu-logo.svg" alt="logo1"></router-link>
				<div class="logofolio-grid-item"><img src="../assets/logofolio/publow-logo.svg" alt="logo2"></div>
				<div class="logofolio-grid-item"><img src="../assets/logofolio/mullmar-logo.svg" alt="logo3"></div>
				<div class="logofolio-grid-item"><img src="../assets/logofolio/kompozit-logo.svg" alt="logo4"></div>
				<div class="logofolio-grid-item"><img src="../assets/logofolio/folkapp-logo.svg" alt="logo5"></div>
				<div class="logofolio-grid-item"><img src="../assets/logofolio/scroll-roll-design-logo.svg" alt="logo6"></div>
				<div class="logofolio-grid-item"><img src="../assets/logofolio/expoil-loog.svg" alt="logo7"></div>
				<div class="logofolio-grid-item"><img src="../assets/logofolio/tekergo-logo.svg" alt="logo8"></div>
				<div class="logofolio-grid-item"><img src="../assets/logofolio/wire-donkey-logo.svg" alt="logo9"></div>
				<div class="logofolio-grid-item"><img src="../assets/logofolio/element-logo.svg" alt="logo10"></div>
				<div class="logofolio-grid-item"><img src="../assets/logofolio/night-manager-logo.svg" alt="logo11"></div>
				<div class="logofolio-grid-item"><img src="../assets/logofolio/protein-logo.svg" alt="logo12"></div>
			</div>

		</div>

		<Contact></Contact>

	</div>
</template>

<script lang="ts">
import Contact from "@/components/Contact.vue";
import Navbar from "@/components/Navbar.vue";
import {defineComponent} from "vue";
import {RouteLocationNormalized, RouteLocationNormalizedLoaded} from "vue-router";

export default defineComponent({
	components: {
		Contact,
		Navbar
	},
	data() {
		return {
			isActive: 'ux-ui',
			isWebHovered: false,
			isPhoneHovered: false,
			isSoftwareHovered: false,
			isMarketingHovered: false,
			isBrandHovered: false,
			isRedesignHovered: false,
			workItems: [
				{
					alt: "kompozit",
					src: "kompozit.svg",
					title: "Kompoz.it",
					description: "Field service management solution for documenting the work of service",
					link: "/case-study/kompozit",
				},
				{
					alt: "romanian-friend",
					src: "romfriend.svg",
					title: "Romanian Friend",
					description: "Day trips and unique Romanian experiences",
					link: "/case-study/romanian-friend",
				},
				{
					alt: "hotbed",
					src: "hotbed.svg",
					title: "Hotbed",
					description: "Further’s internal software for finances, project management and statistics. My very first UX and UI project.",
					link: "/case-study/hotbed",
				},
				{
					alt: "szulinapi-ujsag",
					src: "szulinapiujsag.svg",
					title: "Szülinapi újság",
					description: "UX and UI redesign of a page from where customers can buy newspaper as a gift",
					link: "/case-study/szulinapi-ujsag",
				},
				{
					alt: "contractual",
					src: "contractual.svg",
					title: "Contractual",
					description: "Redesigning an application that facilitates the management, creation, and storage of contracts for a law firm.",
					link: "/case-study/contractual",
				},
			],
			currentWorkIndex: 0,
			posts: [
				{
					title: 'FIRST STEPS TO CREATING A WIREFRAME',
					description:
						'Wireframing is essential for creating successful digital products, helping outline structure and functionality before detailing.',
					date: 'July 15th, 2024',
					link: 'https://gofurther.digital/en/blog/posts/first-steps-to-creating-wireframes',
				},
				{
					title: 'HOW TO RUN SUCCESSFUL USER INTERVIEWS?',
					description:
						'User interviews gather insights from current or potential users about their preferences and experiences, helping evaluate usability, user experience, and refine user personas.',
					date: 'Jan 13th, 2023',
					link: 'https://gofurther.digital/en/blog/posts/how-to-run-successful-user-interviews',
				},
				{
					title: 'LOW AND HIGH FIDELITY PROTOTYPES',
					description:
						'When should you use low-fidelity prototypes? And when should you go for high-fidelity one? How do these both fit into user research? Find out by clicking on the read more button.',
					date: 'Nov 28th, 2022',
					link: 'https://gofurther.digital/en/blog/posts/low-and-high-fidelity-prototypes',
				},
				{
					title: 'CONDUCTING USABILITY TESTS',
					description:
						'Usability testing is a crucial step in product development. But what happens during a usability interview and how should you facilitate such a test?',
					date: 'Sept 29th, 2022',
					link: 'https://gofurther.digital/en/blog/posts/conducting-usability-tests',
				},
				{
					title: 'WHAT IS CUSTOMER JOURNEY MAPPING?',
					description:
						'A well-designed customer journey map keeps the customer at the forefront of the decision-making process and highlights any struggles that customers might experience.',
					date: 'Aug 25th, 2022',
					link: 'https://gofurther.digital/en/blog/posts/what-is-customer-journey-mapping',
				},
				{
					title: 'HOW TO USE THE VALUE PROPOSITION CANVAS?',
					description:
						'Why is your value proposition an important part of your business plan? How to formulate it? How to visualize it using the value proposition canvas?',
					date: 'May 18th, 2022',
					link: 'https://gofurther.digital/en/blog/posts/how-to-use-the-value-proposition-canvas',
				},
				{
					title: 'HOW TO USE PERSONAS?',
					description:
						'What are personas, why are they important in product development and how to create them? Find out by reading this in-depth article about personas.',
					date: 'Feb 14th, 2022',
					link: 'https://gofurther.digital/en/blog/posts/how-to-use-personas-in-product-development',
				},
			],
			currentPostIndex: 0,
			uxUiItems: [
				{
					title: 'Have an idea for a digital product but don’t know how to realize it?',
					text: 'A UX/UI designer can help you design your product from initial idea straight to the testing stage. By that stage, you will have a prototype with pixel-perfect elements. It can also help you generate more leads and improve conversion rates. This translates to better revenues for your company.',
				},
				{
					title: 'Already have a product but want to improve the user experience or interface?',
					text: 'A UX/UI designer’s priority is to continually look for ways to improve the product experience, even for products that have been on the market for years. They may do this by making the product faster, easier to use, or more aesthetic.',
				},
				{
					title: 'Don’t want to waste time and money in the development stage?',
					text: 'Features and fundamental design principles are tested on potential users in order to get feedback whether your product is user-friendly and it has a product-market and solution-product fit. Then it will be handed over to development.',
				},
			],
		};
	},
	methods: {
		setActive(group: string): void {
			this.isActive = group;
		},
		toggleHover(content: string, value: boolean): void {
			switch (content) {
				case 'web':
					this.isWebHovered = value;
					break;
				case 'phone':
					this.isPhoneHovered = value;
					break;
				case 'software':
					this.isSoftwareHovered = value;
					break;
				case 'marketing':
					this.isMarketingHovered = value;
					break;
				case 'brand':
					this.isBrandHovered = value;
					break;
				case 'redesign':
					this.isRedesignHovered = value;
					break;
				default:
					break;
			}
		},
		nextWorkSlide() {
			this.currentWorkIndex = (this.currentWorkIndex + 1) % this.workItems.length;
		},
		prevWorkSlide() {
			this.currentWorkIndex = (this.currentWorkIndex - 1 + this.workItems.length) % this.workItems.length;
		},
		nextBlogSlide() {
			this.currentPostIndex = (this.currentPostIndex + 1) % this.posts.length;
		},
		prevBlogSlide() {
			this.currentPostIndex = (this.currentPostIndex - 1 + this.posts.length) % this.posts.length;
		},
	}
});
</script>

<style scoped>

</style>
