<template>
	<div class="is-relative">
		<Navbar></Navbar>
		<div class="szulinapi-container">
			<img alt="arrow-left" class="arrow-left hide-on-mobile-tablet" src="../../assets/chevron-right-white.svg"
			@click="goBack"/>
			<div class="case-study-title-container">
				<div class="case-study-title">Szülinapi újság</div>
				<div class="case-study-text mt-30">
					The perfect birthday gift.
				</div>
			</div>
		</div>

		<div class="case-study-second-panel-container">
			<div class="second-panel-box">
				<h2 class="mb-30">Szülinapi Újság</h2>
				<p class="width-60p">
					The birthday newspaper is a website where you can order as a gift a replica of the newspaper that
					was published on
					the day the celebrant was born. We create a replica from the newspapers published on that day,
					featuring a unique article and an infant picture.
				</p>
			</div>

			<img alt="hotbed-screen" class="flatco-screen hide-on-mobile-tablet" src="../../assets/case-study/szulinapi-screen.svg"/>
		</div>

		<div class="third-panel-container">
			<div class="overview-title">Project Overview</div>
			<div class="third-panel-grid">
				<div>
					<div class="overview-subtitle">CHALLENGE</div>
					<div class="overview-text mb-30">Optimizing the order process for efficiency and crafting the UI to
						emulate an authentic vintage newspaper in a modern style.
					</div>
					<div class="overview-subtitle">SOLUTION</div>
					<ol>
						<li class="overview-text">Restructuring the order process</li>
						<li class="overview-text">Illustrating new icons</li>
						<li class="overview-text">Finding the balance between the vintage and the modern</li>
					</ol>
				</div>
				<div>
					<div class="overview-subtitle">ROLE</div>
					<div class="overview-text mb-30">UX/UI & Graphic Designer</div>
					<div class="overview-subtitle">TIME</div>
					<div class="overview-text mb-30">5 weeks</div>
					<div class="overview-subtitle">TASK</div>
					<div class="overview-text mb-30">Design 2 sections</div>
					<div class="overview-subtitle">TOOL</div>
					<div class="overview-text mb-30">Figma, Adobe Illustrator, Photoshop</div>
				</div>
			</div>
		</div>

		<div class="fourth-panel-container">
			<h2 class="mb-50 mt-30">Design process</h2>
			<div class="hide-on-desktop mb-50">
				<div class="carousel-container">
					<div class="carousel" :style="{ transform: `translateX(-${currentIndex * 100}%)` }">
						<div class="carousel-item" v-for="(item, index) in items" :key="index">
							<img :alt="item.alt" class="category-icon" :src="require(`@/assets/icons/${item.src}`)" />
							<div class="design-type-title">{{ item.title }}</div>
							<div class="design-type-description" v-for="(desc, i) in item.description" :key="i">{{ desc }}</div>
						</div>
					</div>

					<img alt="arrow-left" class="carousel-control left" src="../../assets/arrow-down.svg" @click="prevSlide"/>
					<img alt="arrow-right" class="carousel-control right" src="../../assets/arrow-down.svg" @click="nextSlide"/>
				</div>
			</div>

			<div class="ux-ui-design-type-container hide-on-mobile-tablet">
				<div class="hide-on-mobile-tablet">
					<div class="design-process-container">
						<div v-for="(item, index) in items"
							 :key="index"
							 class="ux-ui-design-item">
							<img :alt="item.alt" :src="require(`@/assets/icons/${item.src}`)" class="category-icon"/>
							<div class="design-type-title">{{ item.title }}</div>
							<div class="design-type-description" v-for="(desc, i) in item.description" :key="i">{{ desc }}</div>
						</div>
						<div class="dashed-line"></div>
					</div>
				</div>

				<div class="dashed-line"></div>
			</div>

			<div class="half-grid">
				<div>
					<img alt="landing" class="szulinapi-landing" src="../../assets/case-study/szulinapi-landing.svg"/>
				</div>

				<div class="mt-30">
					<h2 class="mb-30">Discovery</h2>
					<div class="design-process-text mb-30">
						In this stage, I thoroughly reviewed the existing website,
						meticulously defining and documenting all the issues present in the current version.
					</div>

					<div class="category-subtitle mb-30">
						Problems
					</div>
					<div class="box-container mb-50">
						<div>
							<div class="services-shadowed-boxes fw-300">
								The landing page was too cluttered
							</div>
						</div>
						<div>
							<div class="services-shadowed-boxes fw-300">
								unclear buttons
							</div>
						</div>
						<div>
							<div class="services-shadowed-boxes fw-300">
								inadequate step order in the ordering process
							</div>
						</div>
						<div>
							<div class="services-shadowed-boxes fw-300">
								the quality of the images used
							</div>
						</div>
						<div>
							<div class="services-shadowed-boxes fw-300">
								outdated design
							</div>
						</div>
					</div>

					<div class="category-subtitle mb-30">
						Hotjar
					</div>

					<div class="design-process-text mb-50">
						During the ideation stage, I sought solutions to address the problems identified in the discovery stage.
						Utilizing Hotjar, I created a heatmap to understand user interests on the landing page and determine which
						information was most valuable to them. Subsequently, I removed unnecessary sections to declutter the page.
					</div>

					<h2 class="mb-30">Ideation</h2>
					<div class="design-process-text mb-50">
						During the ideation stage, I sought solutions for the problems identified in the discovery stage.
						Utilizing Hotjar, I generated a heatmap to discern user interests and determine the information deemed
						useful on the landing page. Consequently, I removed unnecessary sections to declutter the interface.
					</div>

					<div class="category-subtitle mb-30">
						Solutions
					</div>
					<div class="box-container mb-50">
						<div>
							<div class="services-shadowed-boxes fw-300">
								unified, clean landing page
							</div>
						</div>
						<div>
							<div class="services-shadowed-boxes fw-300">
								clear, functional buttons
							</div>
						</div>
						<div>
							<div class="services-shadowed-boxes fw-300">
								giving a new vintage design in a modern way
							</div>
						</div>
						<div>
							<div class="services-shadowed-boxes fw-300">
								restructuring the order process step by step
							</div>
						</div>
						<div>
							<div class="services-shadowed-boxes fw-300">
								replacing poor-quality images with icons and illustrations
							</div>
						</div>
					</div>

					<h2 class="mb-30">Design</h2>
					<div class="design-process-text">
						It was challenging to strike a balance between the old and the new.
						The website required a genuine vintage newspaper appearance while maintaining modern functionality.
						I designed retro-inspired icons and illustrations and crafted a new logo to align with the updated aesthetic.
						<br><br>
						<div class="underline">Szülinapi újság design Figma link</div>
					</div>
				</div>
			</div>
		</div>

		<div class="szulinapi-design text-center">
			<div class=" white-title">
				UI desgin
			</div>

			<img alt="szulinapi-design" class="szulinapi-design-image" src="../../assets/case-study/szulinapi-design.svg"/>
		</div>

		<div class="footer-container mb-30">
			<router-link class="is-flex pointer" to="/case-study/hotbed">
				<img alt="chevron-left" class="footer-arrow" src="../../assets/arrow-right.png"/>
			</router-link>
			<div class="thanks">Thanks for watching!</div>
			<router-link class="is-flex pointer" to="/case-study/contractual">
				<img alt="chevron-left" class="footer-arrow" src="../../assets/arrow-left.png"/>
			</router-link>
		</div>

		<img alt="chevron-up" class="footer-arrow-up" src="../../assets/arrow-up.png" v-on:click="goToTop"/>
	</div>
</template>

<script lang="ts">
import {defineComponent} from "vue";
import Navbar from "@/components/Navbar.vue";
import {RouteLocationNormalized, RouteLocationNormalizedLoaded} from "vue-router";

export default defineComponent({
	components: {
		Navbar
	},
	data() {
		return {
			items: [
				{
					alt: "discovery",
					src: "discovery.svg",
					title: "Discovery",
					description: ["Defining problem"],
				},
				{
					alt: "ideation",
					src: "ideation.svg",
					title: "Ideation",
					description: ["Brainstorming"],
				},
				{
					alt: "design",
					src: "design.svg",
					title: "Design",
					description: ["UX Design", "Ui Design"],
				},

				{
					alt: "test",
					src: "test.svg",
					title: "Test",
					description: ["Usability testing"],
				},
			],
			currentIndex: 0,
		}
	},
	methods: {
		goBack() {
			this.$router.go(-1);
		},
		goToTop() {
			window.scrollTo(0, 0);
		},
		nextSlide() {
			this.currentIndex = (this.currentIndex + 1) % this.items.length;
		},
		prevSlide() {
			this.currentIndex = (this.currentIndex - 1 + this.items.length) % this.items.length;
		},
	}
});

</script>

<style scoped>

</style>
