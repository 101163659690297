<template>
	<div class="is-relative">
		<Navbar></Navbar>
		<div class="hotbed-container">
			<img alt="arrow-left" class="arrow-left hide-on-mobile-tablet" src="../../assets/chevron-right-white.svg" @click="goBack"/>
			<div class="case-study-title-container">
				<div class="case-study-title">Hotbed</div>
				<div class="case-study-text mt-30">
					Further's internal software for <br> managing and monitoring projects <br> and documents.
				</div>
			</div>
		</div>

		<div class="case-study-second-panel-container">
			<div class="second-panel-box">
				<h2 class="mb-30">Hotbed</h2>
				<p class="width-90p">
					Hotbed is Further's internal software for managing and monitoring projects, finances, statistics, and documents.
					It aids in daily tasks such as handling invoices, managing cash flows, storing documents like contracts,
					and reporting the company's financial metrics for efficient process mapping.
					<br> <br>
					This marked my initial project involvement, and since certain UX stages were already completed,
					I didn't need to go through the entire UX process from the beginning.
				</p>
			</div>

			<img alt="hotbed-screen" class="hotbed-screen hide-on-mobile-tablet" src="../../assets/case-study/hotbed-screen.svg"/>
		</div>

		<div class="third-panel-container">
			<div class="overview-title">Project Overview</div>
			<div class="third-panel-grid">
				<div>
					<div class="overview-subtitle">CHALLENGE</div>
					<div class="overview-text mb-30">Design two of the software menu points: Financial reports and Documents.</div>

					<div class="overview-subtitle">SOLUTION</div>
					<ol>
						<li class="overview-text">Design section that meets the goals of the company and user (in this case the user is the owner of the company and the manager).</li>
						<li class="overview-text">Design sections that helps the company’s efficiency.</li>
					</ol>
				</div>

				<div>
					<div class="overview-subtitle">ROLE</div>
					<div class="overview-text mb-30">UX/UI Designer</div>
					<div class="overview-subtitle">TIME</div>
					<div class="overview-text mb-30">7 weeks</div>
					<div class="overview-subtitle">TASK</div>
					<div class="overview-text mb-30">Design 2 sections</div>
					<div class="overview-subtitle">TOOL</div>
					<div class="overview-text mb-30">Figma</div>
				</div>
			</div>
		</div>

		<div class="fourth-panel-container">
			<h2 class="mb-50">Design process</h2>
			<div class="hide-on-desktop">
				<div class="carousel-container">
					<div class="carousel" :style="{ transform: `translateX(-${currentIndex * 100}%)` }">
						<div class="carousel-item" v-for="(item, index) in items" :key="index">
							<img :alt="item.alt" class="category-icon" :src="require(`@/assets/icons/${item.src}`)" />
							<div class="design-type-title">{{ item.title }}</div>
							<div class="design-type-description" v-for="(desc, i) in item.description" :key="i">{{ desc }}</div>
						</div>
					</div>

					<img alt="arrow-left" class="carousel-control left" src="../../assets/arrow-down.svg" @click="prevSlide"/>
					<img alt="arrow-right" class="carousel-control right" src="../../assets/arrow-down.svg" @click="nextSlide"/>
				</div>
			</div>

			<div class="ux-ui-design-type-container hide-on-mobile-tablet">
				<div class="hide-on-mobile-tablet">
					<div class="design-process-container">
						<div v-for="(item, index) in items"
							 :key="index"
							 class="ux-ui-design-item">
							<img :alt="item.alt" :src="require(`@/assets/icons/${item.src}`)" class="category-icon"/>
							<div class="design-type-title">{{ item.title }}</div>
							<div class="design-type-description" v-for="(desc, i) in item.description" :key="i">{{ desc }}</div>
						</div>
						<div class="dashed-line"></div>
					</div>
				</div>

				<div class="dashed-line"></div>
			</div>

			<h2 class="mb-30 mt-30">Discovery</h2>
			<div class="half-grid">
				<div class="design-process-text">
					We participated in numerous online workshops and meetings until I comprehended all the needs,
					internal systems, and finances of the company. The metrics were initially unfamiliar,
					necessitating several follow-ups and meetings for a thorough understanding.
				</div>

				<div class="design-process-text">
					I also conducted one-on-one user interviews to understand their needs separately;
					the owner focused more on financial reports, while the project manager emphasized the importance of documents.
				</div>
			</div>

			<h2 class="mb-30">Research</h2>
			<div class="half-grid">
				<div>
					<div class="design-process-text mb-50">
						During the research stage, I organized the information gathered in the discovery stage.
						I analyzed my observations to define the core problems that my team and I had identified up to this point.
					</div>

					<h2 class="mb-30">Ideation</h2>
					<div class="design-process-text">
						My team and I had online meetings where we brainstormed the best solutions to most effectively assist users.
						I defined the overall structure of the content in Hotbed in a manner that is logical and easy to navigate for our users.
					</div>
				</div>

				<img alt="goals" class="goals" src="../../assets/case-study/goals.svg"/>
			</div>

			<h2 class="mb-30">Design</h2>
			<div class="design-process-text">
				I started directly with designing a high-fidelity prototype. <br>
				View the design on Figma by clicking on the link below: Hotbed Figma design
				<br><br>
				<mark>Hotbed Figma design</mark>
			</div>
		</div>

		<div class="hotbed-documents-container">
			<div class="hotbed-figma-container is-relative">
				<img alt="reports" class="reports" src="../../assets/case-study/reports.svg"/>
				<img alt="documents" class="documents-image mt-m-210" src="../../assets/case-study/hotbed-documents.svg"/>
			</div>
		</div>

		<div class="footer-container mb-30">
			<router-link class="is-flex pointer" to="/case-study/romanian-friend">
				<img alt="chevron-left" class="footer-arrow" src="../../assets/arrow-right.png"/>
			</router-link>
			<div class="thanks">Thanks for watching!</div>
			<router-link class="is-flex pointer" to="/case-study/szulinapi-ujsag">
				<img alt="chevron-right" class="footer-arrow" src="../../assets/arrow-left.png"/>
			</router-link>
		</div>

		<img alt="chevron-up" class="footer-arrow-up" src="../../assets/arrow-up.png" v-on:click="goToTop"/>
	</div>
</template>

<script lang="ts">
import {defineComponent} from "vue";
import Navbar from "@/components/Navbar.vue";

export default defineComponent({
	components: {
		Navbar
	},
	data() {
		return {
			items: [
				{
					alt: "discovery",
					src: "discovery.svg",
					title: "Discovery",
					description: ["Workshops", "User interviews"],
				},
				{
					alt: "research",
					src: "research.svg",
					title: "Research",
					description: ["Business goals", "Users goals"],
				},
				{
					alt: "ideation",
					src: "ideation.svg",
					title: "Ideation",
					description: ["Brainstorming"],
				},
				{
					alt: "design",
					src: "design.svg",
					title: "Design",
					description: ["Brainstorming", "Site map"],
				},
			],
			currentIndex: 0,
		}
	},
	methods: {
		goBack() {
			this.$router.go(-1);
		},
		goToTop() {
			window.scrollTo(0, 0);
		},
		nextSlide() {
			this.currentIndex = (this.currentIndex + 1) % this.items.length;
		},
		prevSlide() {
			this.currentIndex = (this.currentIndex - 1 + this.items.length) % this.items.length;
		},
	}
});

</script>

<style scoped>

</style>
