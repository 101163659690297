<template>
	<div class="is-relative">
		<Navbar></Navbar>
		<div class="romfriend-container">
			<img alt="arrow-left" class="arrow-left hide-on-mobile-tablet" src="../../assets/chevron-right-white.svg"
				 @click="goBack"/>
			<div class="pt-50">
				<div class="case-study-title text-center">Romanian Friend</div>
				<div class="case-study-text text-center mt-30">
					Day trips and unique Romanian experiences with the best <br> guides from all over the country!
				</div>
			</div>
		</div>

		<div class="case-study-second-panel-container">
			<div class="contractual-second-panel-box">
				<h2 class="mb-30">Romanian Friend</h2>
				<p class="width-40p">
					Day trips and unique Romanian experiences with the best guides from all over the country!
					We make travel easy, safe and exciting for everyone - especially after three challenging years for
					tourism.
					Imagine having a trusted friend from Romania you can ask for help to plan your trip.
				</p>
			</div>

			<img alt="romfriend-screen" class="flatco-screen hide-on-mobile-tablet"
				 src="../../assets/case-study/romfriend-screen.svg"/>
		</div>

		<div class="third-panel-container">
			<div class="overview-title">Project Overview</div>
			<div class="third-panel-grid">
				<div>
					<div class="overview-subtitle">CHALLENGE</div>
					<div class="overview-text mb-30">
						<ul>
							<li class="overview-text">
								Redesigning the UI and restructuring some elements
							</li>
						</ul>
					</div>

					<div class="overview-subtitle">SOLUTION</div>
					<ol>
						<li class="overview-text">Giving the webpage a modern UI</li>
						<li class="overview-text">Consistency</li>
					</ol>

				</div>

				<div>
					<div class="overview-subtitle">ROLE</div>
					<div class="overview-text mb-30">UX/UI Designer</div>
					<div class="overview-subtitle">TIME</div>
					<div class="overview-text mb-30">2 weeks</div>
					<div class="overview-subtitle">TASK</div>
					<div class="overview-text mb-30">UI redesign</div>
					<div class="overview-subtitle">TOOL</div>
					<div class="overview-text mb-30">Figma, Adobe Illustrator</div>
				</div>
			</div>
		</div>

		<div class="design-container">
			<h2 class="design-process-title">Design process</h2>
			<div class="carousel-container hide-on-desktop">
				<div :style="{ transform: `translateX(-${currentIndex * 100}%)` }" class="carousel">
					<div v-for="(item, index) in designProcessItems" :key="index" class="carousel-item">
						<img :alt="item.alt" :src="require(`@/assets/icons/${item.src}`)" class="category-icon"/>
						<div class="design-type-title">{{ item.title }}</div>
						<div class="design-type-description" v-html="item.description"></div>
					</div>
				</div>

				<img alt="arrow-left" class="carousel-control left" src="../../assets/arrow-down.svg"
					 @click="prevSlide"/>
				<img alt="arrow-right" class="carousel-control right" src="../../assets/arrow-down.svg"
					 @click="nextSlide"/>
			</div>

			<div class="hide-on-mobile-tablet">
				<div class="design-process-container">
					<div v-for="(item, index) in designProcessItems"
						:key="index"
						class="ux-ui-design-item">
						<img :alt="item.alt" :src="require(`@/assets/icons/${item.src}`)" class="category-icon"/>
						<div class="design-type-title">{{ item.title }}</div>
						<div class="design-type-description" v-html="item.description"></div>
					</div>
					<div class="dashed-line"></div>
				</div>
			</div>
		</div>

		<div class="wireframing-container">
			<h2 class="mb-30">Wireframing</h2>
			<div class="wireframe-flex-grid">
				<div>
					<div class="design-type-description text-center mb-30">Old design</div>
					<img alt="wireframe-image" class="wireframe-images" src="../../assets/case-study/old-design.svg"/>
				</div>
				<div>
					<div class="design-type-description text-center mb-30">New wireframe</div>
					<img alt="wireframe-image" class="wireframe-images" src="../../assets/case-study/new-wireframe.svg"/>
				</div>
			</div>
		</div>

		<div class="wireframing-container is-light-blue">
			<h2 class="text-center is-white py-30">User Interface</h2>
			<img alt="wireframe-image" class="ui-image" src="../../assets/case-study/romfriend-ui.svg"/>
		</div>

		<div class="wireframing-container">
			<h2 class="mb-30 is-gray text-center">Blog</h2>
			<img alt="wireframe-image" class="ui-image" src="../../assets/case-study/romfriend-blog.svg"/>
		</div>

		<div class="tenant-container is-light-blue">
			<div class="landing-page-title">
				Tour page
			</div>

			<div class="landing-image-container">
				<img alt="flatco-laptop" class="dashboard-image" src="../../assets/case-study/romfriend-tour-page.svg"/>
			</div>
		</div>

		<div class="footer-container mb-30">
			<router-link class="is-flex pointer" to="/case-study/kompozit">
				<img alt="chevron-left" class="footer-arrow-left" src="../../assets/arrow-right.png"/>
			</router-link>
			<div class="thanks">Thanks for watching!</div>
			<router-link class="is-flex pointer" to="/case-study/hotbed">
				<img alt="chevron-right" class="footer-arrow" src="../../assets/arrow-left.png"/>
			</router-link>
		</div>

		<img alt="chevron-up" class="footer-arrow-up" src="../../assets/arrow-up.png" v-on:click="goToTop"/>
	</div>
</template>

<script lang="ts">
import Navbar from "@/components/Navbar.vue";
import {defineComponent} from "vue";

export default defineComponent({
	components: {
		Navbar
	},
	data() {
		return {
			designProcessItems: [
				{
					alt: "discovery",
					src: "discovery.svg",
					title: "Discovery",
					description: "Meetings with stakeholder",
				},
				{
					alt: "research",
					src: "research.svg",
					title: "Research",
					description: "Product research",
				},
				{
					alt: "design",
					src: "design.svg",
					title: "Design",
					description: "Wireframing <br> UI design",
				},
			],
			currentIndex: 0,
		}
	},
	methods: {
		goBack() {
			this.$router.go(-1);
		},
		goToTop() {
			window.scrollTo(0, 0)
		},
		nextSlide() {
			this.currentIndex = (this.currentIndex + 1) % this.designProcessItems.length;
		},
		prevSlide() {
			this.currentIndex = (this.currentIndex - 1 + this.designProcessItems.length) % this.designProcessItems.length;
		},
	}

});

</script>

<style scoped>

</style>
