<template>
	<div class="modal"
		 v-bind:class="{ 'is-active': isActive }"
	>
		<div class="modal-background"></div>
		<div class="modal-body">
			<div class="modal-card is-overflow-visible">
				<header class="modal-card-head">
					<p class="modal-card-title">
						<slot name="title" v-bind:slotProps="slotProps">
							<template v-if="title">{{ title }}</template>
						</slot>
					</p>
				</header>
				<section class="modal-card-body is-overflow-visible" v-if="$slots.body">
					<slot name="body" v-bind:slotProps="slotProps"></slot>
				</section>
				<footer class="modal-card-foot" v-if="$slots.footer">
					<slot name="footer" v-bind:slotProps="slotProps"></slot>
				</footer>
			</div>
		</div>
	</div>
</template>

<script lang="ts">
import {Options, Vue} from 'vue-class-component';
import {Prop, Watch} from "vue-property-decorator";

@Options({

})

export default class Modal extends Vue {
	@Prop({
		required: true,
		type: Function
	}) close!: object;

	@Prop({
		required: true,
		type: Boolean
	}) isActive!: boolean;

	@Prop({
		default: null
	}) slotProps!: any;

	@Prop({
		type: String
	}) title!: string;

	// Watchers
	@Watch('isActive')
	isActiveChanged() {
		if (this.isActive) {
			document.documentElement.style.overflowY = 'hidden';
			return;
		}

		document.documentElement.style.overflowY = 'scroll';
	}
};
</script>
