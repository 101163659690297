<template>
	<div>
		<h2>Product research</h2>
		<p class="mb-50">You do the right research to help you identify your product's competition,
			as well as to identify and understand your product's users.
			Then, your purpose is to build a “better” product than your competitors by understanding the
			problem better than they do.
			<br><br>
			Also, it is the process of finding out how the product or feature you're currently
			working on is progressing, whether there are any changes in your target audience's needs and
			whether you need to make any adjustments to the product.
		</p>

		<div class="design-type-title m-0">Problems it solve</div>

		<ul class="mb-100">
			<li>Identifying your product's competition</li>
			<li>Determine the product’s purpose</li>
			<li>Creating something new and something better</li>
		</ul>

		<h2>User interview</h2>

		<p class="mb-50">The user interview is the type of research organized during any development stage to
			better understand user wants and needs and consequently – create a better product.
		</p>

		<div class="design-type-title m-0">Problems it solve</div>

		<ul class="mb-100">
			<li>Understanding of potential users preferences, thoughts and feelings</li>
			<li>Help create a user persona</li>
			<li>Transform observations into actionable insights</li>
			<li>Confirm or dispute guesses</li>
			<li>Help to fix problems before spending time and money</li>
		</ul>

		<h2>Persona</h2>

		<p class="mb-50">
			Personas are helpful design tools to apply as a focal point to the target audience while creating high-tech products.
			Personas are "a precise definition of our user and what he wishes to accomplish,
			they are not real people but rather hypothetical archetypes of actual users." - Alan Cooper
			<br><br>
			A persona's goals and characteristics represent the needs of a larger group of users.
			Descriptions include behavior patterns, goals, skills, attitudes, and background information,
			as well as the environment in which a persona operates. Personas are not end-products.
			They are constantly changing and can be modified depending on the current state of the product.
		</p>

		<div class="design-type-title m-0">Problems it solve</div>

		<ul class="mb-100">
			<li>Identifying your product's competition</li>
			<li>Determine the product’s purpose</li>
			<li>Creating something new and something better</li>
		</ul>

		<div class="text-center mt-100 mb-100">
			<router-link class="button button--coral" to="/get-in-touch">Ask for an offer</router-link>
		</div>

		<div class="ux-ui-title mb-50">Why work with a <mark>UX/UI designer</mark>?</div>

		<div class="ux-ui-container">
			<div class="ux-ui-item">
				<div class="ux-ui-item-title-container">
					<img alt="tick" class="tick" src="../../assets/tick.svg"/>
					<div class="ux-ui-item-title">Have an idea for a digital product but don’t know how to realize it?</div>
				</div>

				<div class="ux-ui-item-text">
					A UX/UI designer can help you design your product from initial idea straight to the testing stage.
					By that stage, you will have a prototype with pixel-perfect elements.
					It can also help you generate more leads and improve conversion rates.
					This translates to better revenues for your company.
				</div>
			</div>

			<div class="ux-ui-item">
				<div class="ux-ui-item-title-container">
					<img alt="tick" class="tick" src="../../assets/tick.svg"/>
					<div class="ux-ui-item-title">Already have a product but want to improve the user experience or interface?</div>
				</div>

				<div class="ux-ui-item-text">
					A UX/UI designer’s priority is to continually look for ways to improve the product experience,
					even for products that have been on the market for years.
					They may do this by making the product faster, easier to use, or more aesthetic.
				</div>
			</div>

			<div class="ux-ui-item">
				<div class="ux-ui-item-title-container">
					<img alt="tick" class="tick" src="../../assets/tick.svg"/>
					<div class="ux-ui-item-title">Don’t want to waste time and money in the development stage?</div>
				</div>

				<div class="ux-ui-item-text">
					Features and fundamental design principles are tested on potential users in order to get
					feedback whether your product is user-friendly and it has a product-market and solution-product fit.
					Then it will be handed over to development.
				</div>
			</div>
		</div>

	</div>
</template>

<script lang="ts">
import {Options, Vue} from 'vue-class-component';
import {Prop} from "vue-property-decorator";
import Contact from "@/components/Contact.vue";

@Options({
	components: {Contact}
})
export default class Research extends Vue {

}
</script>

<style scoped>

</style>
