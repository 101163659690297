<template>
	<div>
		<h2>Brainstorming</h2>

		<p class="mb-50">Brainstorming is about coming up with solutions for the challenges defined in the discovery stage.
			This is where to come up with innovative ideas. Brainstorming helps teams generate a large number of ideas quickly,
			which can be refined and merged to create the ideal solution, but it also can be done individually.
		</p>

		<div class="design-type-title m-0">Problems it solve</div>

		<ul class="mb-100">
			<li>Generating innovative ideas</li>
			<li>Finding answers and solutions to problems and challenges</li>
			<li>Sketching solutions, getting a visual picture of the whole</li>
		</ul>

		<h2>Workshops</h2>

		<p class="mb-50">Product development workshops provide a framework to help designing, prototyping,
			and testing the viability of an idea, product, or feature. Most popular rapid workshop is the 5-day Design Sprint,
			but there are specific workshops that can meet your needs.
		</p>

		<div class="design-type-title m-0">Problems it solve</div>

		<ul class="mb-100">
			<li>Introducing a new idea, or may illustrate and promote actual process practice</li>
			<li>Discussing questions</li>
			<li>Brainstorm ideas</li>
			<li>Identify problems</li>
			<li>Make decisions and develop solutions</li>
		</ul>

		<div class="text-center mt-100 mb-100">
			<router-link class="button button--coral" to="/get-in-touch">Ask for an offer</router-link>
		</div>


	</div>
</template>
<script lang="ts">
import {Options, Vue} from 'vue-class-component';
import {Prop} from "vue-property-decorator";
import Contact from "@/components/Contact.vue";

@Options({
	components: {Contact}
})
export default class Ideation extends Vue {

}
</script>

<style scoped>

</style>
