<template>
	<div class="is-relative">
		<Navbar></Navbar>
		<div class="pertu-container">
			<img alt="arrow-left" class="arrow-left" src="../../assets/chevron-right-white.svg"
			@click="goBack"/>
			<img alt="pertu" class="pertu" src="../../assets/case-study/pertu-icon.svg"/>
		</div>

		<div class="pertu-main-panel mb-100">
			<img alt="pertu" class="pertu-backround-image" src="../../assets/case-study/pertu-background.svg"/>
			<div class="pertu-main-container">
				<div class="width-60p mb-100">
					<div class="pertu-title mb-50">About the project</div>
					<div class="pertu-text">
						Pertu is a podcast studio operated in Háromszék.
						They sought a brand that would effectively convey the essence of the name "pertu,"
						which in Hungarian signifies an informal relationship between two or more people.
						In Hungarian culture, when individuals engage in a "pertu" over drinks, they mutually
						affirm the use of informal addressing. <br><br>Given the significance of the name, the brand
						was designed to be friendly, informal, and straightforward, while also reflecting its
						connection to the podcast.
					</div>
				</div>

				<div class="pertu-title mb-100">Logo design by grid method</div>
				<img alt="pertu-logo" class="pertu-logo center" src="../../assets/case-study/pertu-logo-black-white.svg"/>
			</div>
		</div>

		<div class="pertu-main-container">
			<div class="pertu-title mb-50">Logo elements and meaning</div>
			<div class="pertu-inline-container mb-50">
				<div class="pertu-item">
					<div class="pertu-text">Name</div>
					<img alt="pertu-logo" class="pertu-logo center"
						 src="../../assets/case-study/pertu-name.svg"/>
				</div>

				<div class="pertu-item">
					<div class="pertu-text">Glass</div>
					<img alt="pertu-logo" class="pertu-logo center"
						 src="../../assets/case-study/pertu-glass.svg"/>
				</div>

				<div class="pertu-item">
					<div class="pertu-text">Microphone</div>
					<img alt="pertu-logo" class="pertu-logo center"
						 src="../../assets/case-study/pertu-microphone.svg"/>
				</div>

				<div class="pertu-item">
					<div class="pertu-text">Territory</div>
					<img alt="pertu-logo" class="pertu-logo center"
						 src="../../assets/case-study/pertu-territory.svg"/>
				</div>
			</div>

			<div class="pertu-inline-container mb-100">
				<div class="pertu-item">
					<img alt="pertu-logo" class="pertu-trasparent-logo center"
						 src="../../assets/case-study/pertu1.svg"/>
				</div>

				<div class="pertu-item">
					<img alt="pertu-logo" class="pertu-trasparent-logo center"
						 src="../../assets/case-study/pertu2.svg"/>
				</div>

				<div class="pertu-item">
					<img alt="pertu-logo" class="pertu-trasparent-logo center"
						 src="../../assets/case-study/pertu3.svg"/>
				</div>

				<div class="pertu-item">
					<img alt="pertu-logo" class="pertu-trasparent-logo center"
						 src="../../assets/case-study/pertu4.svg"/>
				</div>
			</div>


			<div class="pertu-title mb-50">Colors</div>
			<div class="half-grid mb-100">
				<div class="is-flex gap-100">
					<div class="is-relative">
						<div class="box light-blue"></div>
						<div class="box grey"></div>
						<div class="box yellow"></div>
					</div>

					<div class="flex-column">
						<div class="flex-column-color">
							<div class="pertu-subtitle">Turquoise</div><br>
							<div class="pertu-text">
								HEX: #30D5C8 <br>
								RGB: 48 213 71 100 <br>
								CMYK: 64 0 32 0 <br>
							</div>
						</div>

						<div class="flex-column-color">
							<div class="pertu-subtitle">Grey</div><br>
							<div class="pertu-text">
								HEX: #343947 <br>
								RGB: 52 57 71 100 <br>
								CMYK: 79 67 47 49 <br>
							</div>
						</div>

						<div class="flex-column-color">
							<div class="pertu-subtitle">Yellow</div><br>
							<div class="pertu-text">
								HEX: #FFFF66 <br>
								RGB: 255 255 102 100 <br>
								CMYK: 7 0 70 0 <br>
							</div>
						</div>
					</div>
				</div>

				<div class="center flex-column gap-30">
					<div class="wide-box">
						<img alt="pertu-yellow-logo"
							 class="pertu-yellow-logo" src="../../assets/case-study/pertu-green.svg"/>
					</div>

					<div class="wide-box">
						<img alt="pertu-yellow-logo"
							 class="pertu-yellow-logo" src="../../assets/case-study/pertu-black.svg"/>
					</div>

					<div class="wide-box grey-background">
						<img alt="pertu-yellow-logo"
							 class="pertu-yellow-logo" src="../../assets/case-study/pertu-yellow.svg"/>
					</div>
				</div>
			</div>

			<div class="is-flex gap-30 mb-100">
				<div class="square-box grey-background"></div>
				<div class="square-box yellow-background"></div>
				<div class="square-box light-blue-background"></div>
				<div class="square-box grey-background"></div>
			</div>

			<div class="pertu-title mb-50">Typography</div>
		</div>

		<div class="pertu-image-container">
			<div class="pertu-image-1"></div>
			<div class="pertu-image-2"></div>
		</div>

		<div class="footer-container mb-30">
			<div class="thanks">Thanks for watching!</div>
		</div>
		<img alt="chevron-up" class="footer-arrow-up" src="../../assets/arrow-down.svg" v-on:click="goToTop"/>
	</div>
</template>

<script lang="ts">
import Navbar from "@/components/Navbar.vue";
import {defineComponent} from "vue";

export default defineComponent({
	components: {
		Navbar
	},
	methods: {
		goBack() {
			this.$router.go(-1);
		},
		goToTop() {
			window.scrollTo(0, 0)
		}
	}
});
</script>

<style scoped>

</style>
