<template>
	<div>
		<h2>Usability testing</h2>

		<p class="mb-50">Usability testing serves as feedback to evaluate a product or service by testing it with potential users.
			The researcher (called a "facilitator" or a "moderator") asks a participant to perform tasks,
			usually using specific user interfaces. The researcher monitors the participant's behaviour and listens for
			feedback while each task is completed.
		</p>

		<div class="design-type-title m-0">Problems it solve</div>

		<ul class="mb-100">
			<li>Identifying issues in the design or user experience of the product or service</li>
			<li>Uncovering opportunities for enhancement</li>
			<li>Discovering and learning about the target user's behaviour and preference</li>
		</ul>

		<div class="text-center mt-100 mb-100">
			<router-link class="button button--coral" to="/get-in-touch">Ask for an offer</router-link>
		</div>

	</div>

</template>

<script lang="ts">
import {Options, Vue} from 'vue-class-component';
import {Prop} from "vue-property-decorator";
import Contact from "@/components/Contact.vue";

@Options({
	components: {Contact}
})
export default class Test extends Vue {

}
</script>

<style scoped>

</style>
